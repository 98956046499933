import { Link, navigate } from "gatsby";
import React, { useState } from "react";
import { Container } from "../../styles/UI";
import Button from "../../DesignSystem/Button";
import Input from "../../DesignSystem/Input";

import pbiPartnerLogo from "../../images/footer/pbi-partner-footer.png";
import top10logo from "../../images/footer/top10rankings.png";
import msstartupLogo from "../../images/footer/ms-startup-footer.png";
import inovabraLogo from "../../images/footer/inovabra-footer.png";
import startupBadge from "../../images/footer/startup-badge.png";
import gptwBadge from "../../images/footer/selo-gptw 1.svg";
import gptwBadge23 from "../../images/footer/selo-gptw-2023.svg";
import top10logo2022 from "../../images/footer/BigData_Branco.png";

import facebookIcon from "../../images/footer/fb-icon.svg";
import linkedinIcon from "../../images/footer/li-icon.svg";
import instagramIcon from "../../images/footer/ig-icon.svg";

import mailIcon from "../../images/footer/mail.png";
import mappinIcon from "../../images/footer/map-pin.png";
import telIcon from "../../images/footer/whatsapp.png";
import telIcon1 from "../../images/footer/phone_in_talk.png";

import * as S from "./style";

export default function Footer(props) {
  const [email, setEmail] = useState("");

  const handleClick = () => {
    // modalRef1.current.openModal();
    if (props.taxdashs) {
      if (typeof window !== "undefined")
        window.location.href = `https://azuremarketplace.microsoft.com/pt-br/marketplace/apps/taxcel.taxdashs?tab=overview`;
      return;
    }

    navigate("/baixar-taxsheets#formulario", { state: { email } });
  };

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setEmail(value);
  };
  return (
    <S.Footer>
      <Container>
        <div>
          <S.Partners>
            <img src={msstartupLogo} alt="Logo Microsoft for Startups" />
            <img src={startupBadge} alt="Logo PowerBI partner" />
            <img src={inovabraLogo} alt="Logo Inovabra habitat" />
            <img
              src={top10logo2022}
              alt="Logo Open 100 startups top 10 ranking legaltechs e top 10 ranking big data"
            />
            <img
              src={gptwBadge23}
              alt="Selo de certificação Great Place To Work"
            />
          </S.Partners>
          <S.Taxcel>
            <img src="/logo.png" alt="Logo Taxcel" />
            <p>A gente simplifica. Você analisa.</p>
            <div>
              <a
                href="https://www.facebook.com/taxceladdins/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebookIcon} alt="ícone Facebook" />
              </a>
              <a
                href="https://www.instagram.com/taxcel_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagramIcon} alt="ícone Instagram" />
              </a>
              <a
                href="https://www.linkedin.com/company/taxcel/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedinIcon} alt="ícone Linkedin" />
              </a>
            </div>
          </S.Taxcel>
          <S.Links>
            <div>
              <h4>Produtos</h4>
              <Link to="/taxdashs">TaxDashs</Link>
              <Link to="/taxsheets">TaxSheets</Link>
              <Link to="/planos">Planos</Link>
              <a href="https://taxceladdins.zendesk.com/hc/pt-br">
                Portal de ajuda
              </a>
              <a href="https://taxceladdins.zendesk.com/hc/pt-br/sections/115001817087-Perguntas-Frequentes">
                FAQ
              </a>
              <a href="https://taxceladdins.zendesk.com/hc/pt-br/sections/115001816827--TaxSheets-Editor-SPED-V%C3%ADdeos-Tutoriais">
                Tutoriais
              </a>
              <Link to="/termos-de-uso">Termos de uso</Link>
            </div>
            <div>
              <h4>Institucional</h4>
              <Link to="/sobre">Quem somos</Link>
              <Link to="/contato">Fale conosco</Link>
              <a href="https://blog.taxceladdins.com.br/">Blog</a>

              <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=Q11Ffv4l2UuxcJR9hsUcUAZQ644QVKRAtqM3YQre8UtUNENZTDM5RFpDTFFPVjRCN1FPMDRPWkFHWiQlQCN0PWcu">
                Trabalhe conosco
              </a>
              <Link to="/politica-de-privacidade">Política de Privacidade</Link>
              <Link to="/politica-de-seguranca-da-informacao">
                Política de Segurança <br /> da Informação
              </Link>
              <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=Q11Ffv4l2UuxcJR9hsUcUGZ4KBrc-D5LoJFsewsDfhpUMTZLVkpTWFhCMVE1Vk9VTjYzQVdaUDcwVC4u">
                Formulário de LGPD
              </a>
              <a href="https://forms.office.com/r/VNgFp68WEK">
                Canal de denúncias
              </a>
              <a href="https://app.drata.com/trust/a16d79c1-52a8-45d7-a51a-e7b0c9fe2734">
                Central de Segurança
              </a>
            </div>
            <div>
              <h4>Parceiros</h4>
              <a href="https://sites.google.com/view/parceiros-taxcel/home">
                Portal
              </a>{" "}
              {/* <a href="https://conteudo.taxcel.com.br/indique-ganhe">
                Indique e ganhe
              </a> */}
            </div>
            <div className="contato">
              <h4>Contato</h4>
              <div className="icone-texto">
                <img src={mailIcon} alt="ícone de email" />
                <div>
                  <a href="mailto@contato@taxcel.com.br">
                    contato@taxcel.com.br
                  </a>
                  <a href="mailto@suporte@taxcel.com.br">
                    suporte@taxcel.com.br
                  </a>
                </div>
              </div>
              <div className="icone-texto">
                <img src={telIcon1} alt="ícone do whatsapp" />
                <p>
                  <a
                    href="https://api.whatsapp.com/send/?phone=5511910221906&text&app_absent=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (11) 5039-4214
                  </a>
                </p>
              </div>
              <div className="icone-texto">
                <img src={mappinIcon} alt="ícone de localização" />
                <div>
                  <p>R. da Consolação, 2302</p>
                  <p>Consolação, São Paulo</p>
                </div>
              </div>
              <p className="atendimento">
                Atendimento de segunda <br />a sexta das 9h às 17h
              </p>
            </div>
            <div>
              <h4>Teste grátis!</h4>
              <Input
                value={email}
                onChange={handleChange}
                short
                negative
                placeholder="Seu email"
              >
                <Button onClick={handleClick}>Teste já</Button>
              </Input>
            </div>
          </S.Links>
        </div>
      </Container>
      <S.Copyright>
        <Container>
          © {new Date().getFullYear()} Taxcel. Todos os direitos reservados.
        </Container>
      </S.Copyright>
    </S.Footer>
  );
}
